<template>
    <el-tabs tab-position="left" v-model="activeTab" stretch>
        <el-tab-pane label="教学综合能力评测" name="first">
            <table1></table1>
        </el-tab-pane>
        <el-tab-pane label="网培政策规范评测" name="second">
            <table2></table2>
        </el-tab-pane>
        <el-tab-pane label="教学数字化能力评测" name="third">
            <table3></table3>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import table1 from './tabel/table1'
import table2 from './tabel/table2'
import table3 from './tabel/table3'
export default {
  name: 'Layout',
  components:{table1,table2,table3},
  data() {
    return {
        activeTab: 'first',
    }
  },
}
</script>
<style lang="less" scoped>
    /deep/.el-tabs__header {
        width: 170px;
        margin-right: 20px!important;
    }
    /deep/.el-tabs__content{
        width: calc(100% - 190px);
    }
    /deep/.el-tabs__content::-webkit-scrollbar {display:none}
    /deep/.el-tabs__nav-wrap::after{
        position: static;
    }
</style>
