<template>
    <div class="Traincourse">
        <div class="introduce">
            <el-row class="flex-col-center">
                <el-col :span="8">
                    <img src="../assets/images/exam/comp.png" alt="">
                </el-col>
                <el-col :span="16">
                    <h1>网培师培训课程介绍</h1>
                    <p>网培师培训课程是针对网培师评测体系制作的教学培训课程,由网培师平台制作并上传,主要用于网培师考生的考前知识培训,课程内容包括:"教学综合能力评测"、“网培政策规范评测”、“教学数字化能力评测“三个科目的课程教学,考生可通过课程的培训学习来提高自身的网络教学能力,以此保证通过考试。网培师平台建议考生先学习课程,再通过模拟练习打磨知识,最后再参加正式考试,从而顺利的获得证书!</p>
                    <el-button @click="study">立即参加课程培训>></el-button>
                </el-col>
            </el-row>
        </div>

        <div class="tabs">
             <el-tabs v-model="activeName">
                <el-tab-pane label="课程介绍" name="first">
                    <div class="info">
                        <img src="../assets/images/exam/title1.png" alt="" class="img_title">
                        <div class="info_txt">
                            <el-row>
                                <el-col :span="2">
                                    <img src="../assets/images/exam/01.png" alt="" class="xuhao">
                                </el-col>
                                <el-col :span='22'>
                                    <p>网培师培训课程是针对网培师评测体系制作的教学培训课程，由网培师平台邀请名师制作，主要用于网培师考生的考前知识培训。</p>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="2">
                                    <img src="../assets/images/exam/02.png" alt="" class="xuhao">
                                </el-col>
                                <el-col :span='22'>
                                    <p>课程内容包括：”教学综合能力评测“”网培政策规范评测““教学数字化能力评测”三个科目的课程教学，考生可通过课程的培训学习来提高自身的网络教学能力，以此来保证通过考试。</p>
                                </el-col>
                            </el-row>
                        </div>
                    </div>

                    <div class="ability">
                        <div class="mx1200"><img src="../assets/images/exam/title2.png" alt="" class="img_title lang"></div>
                        <div class="imgs_item">
                            <h2>核心能力</h2>
                            <el-row :gutter=30>
                                <el-col :span="6"><img src="../assets/images/exam/item1.png" alt=""></el-col>
                                <el-col :span="6"><img src="../assets/images/exam/item2.png" alt=""></el-col>
                                <el-col :span="6"><img src="../assets/images/exam/item3.png" alt=""></el-col>
                                <el-col :span="6"><img src="../assets/images/exam/item4.png" alt=""></el-col>
                            </el-row>
                            <h2>应用场景</h2>
                            <el-row :gutter=30 type="flex" justify="space-around">
                                <el-col :span="3"></el-col>
                                <el-col :span="6"><img src="../assets/images/exam/item5.png" alt=""></el-col>
                                <el-col :span="6"><img src="../assets/images/exam/item6.png" alt=""></el-col>
                                <el-col :span="6"><img src="../assets/images/exam/item7.png" alt=""></el-col>
                                <el-col :span="3"></el-col>
                            </el-row>
                        </div>                       
                    </div>
                    <div class="info">
                        <img src="../assets/images/exam/title3.png" alt="" class="img_title">
                        <el-row type="flex" justify="space-around">
                            <el-col :span="4" class="target_item">
                                <img src="../assets/images/exam/001.png" alt="" class="tag">
                                <div class="cont">
                                    <img src="../assets/images/exam/-s-icon01.png" alt="" class="cover">
                                    <p>培养学员的网络教学能力</p>
                                </div>
                            </el-col>
                            <el-col :span="1"></el-col>
                            <el-col :span="4" class="target_item">
                                <img src="../assets/images/exam/002.png" alt="" class="tag">
                                <div class="cont">
                                    <img src="../assets/images/exam/-s-icon02.png" alt="" class="cover">
                                    <p>培养学员的职业道德与素养</p>
                                </div>
                            </el-col>
                            <el-col :span="1"></el-col>
                            <el-col :span="4" class="target_item">
                                <img src="../assets/images/exam/003.png" alt="" class="tag">
                                <div class="cont">
                                    <img src="../assets/images/exam/-s-icon03.png" alt="" class="cover">
                                    <p>完善学员的网络教学规范</p>
                                </div>
                            </el-col>
                            <el-col :span="1"></el-col>
                            <el-col :span="4" class="target_item">
                                <img src="../assets/images/exam/004.png" alt="" class="tag">
                                <div class="cont">
                                    <img src="../assets/images/exam/-s-icon04.png" alt="" class="cover">
                                    <p>提升学员的执业能力</p>
                                </div>
                            </el-col>
                            <el-col :span="1"></el-col>
                            <el-col :span="4" class="target_item">
                                <img src="../assets/images/exam/005.png" alt="" class="tag">
                                <div class="cont">
                                    <img src="../assets/images/exam/-s-icon05.png" alt="" class="cover">
                                    <p>提升学员的教学数字工具使用能力</p>
                                </div>
                            </el-col>
                        </el-row>
                    </div>

                    <div class="why">
                        <div class="mx1200"><img src="../assets/images/exam/title4.png" alt="" class="img_title"></div>
                        <div class="mx1200 card">
                            <ul>
                                <li><p>因为网培师评测体系是检验学员是否已经具备了网络教学的能力，所以评测的内容及知识比较繁多，要求学员熟练掌握知识才可通过评测。</p></li>
                                <br/>
                                <li><p>网培师平台建议学员<span>先学习网培师课程和参加模拟练习后</span>再前往正式考试。</p></li>
                            </ul>                            
                        </div>
                        <div class="ban">
                            <el-button @click="study">立即参加课程培训>></el-button>
                        </div>
                    </div>

                    <div class="Mask">
                        <div class="what">
                            <div class="mx1200"><img src="../assets/images/exam/title5.png" alt="" class="img_title"></div>
                            <el-row> 
                                <el-col :span="12">
                                    <div class="item">
                                        <img src="../assets/images/exam/0001.png" alt="">
                                        <span>成为网培师所需的能力</span>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div class="item">
                                        <img src="../assets/images/exam/0002.png" alt="">
                                        <span>教育学知识和教育心理学知识</span>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <div class="item">
                                        <img src="../assets/images/exam/0003.png" alt="">
                                        <span>网络教学的政策与规范</span>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div class="item">
                                        <img src="../assets/images/exam/0004.png" alt="">
                                        <span>教学数字工具的应用</span>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="课程目录" name="second">
                    <div class="table">
                        <Classtableleft/>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <!-- 支付弹框 -->
        <el-dialog title="支付" :visible.sync="dialogVisible" width="550px" v-loading="loading" :close-on-click-modal="false" center class="payStyle">
            <div>
                <span>付费内容:</span>
                培训教材、评测练习、正式评测、评测审核、证书制作、证书颁发。
            </div>
            <div v-if="payRadio !== '1'">
                <span>输入兑换码: </span>
                <el-input placeholder="请输入兑换码" class="redeem" v-model="redeemData"></el-input>
                <p class="warnRed">*请注意区分大小写</p>
            </div>
            <div v-else><span>支付金额: </span>{{ this.$price }}</div>
            <div>
                <span>支付方式:</span>
                <el-radio v-model="payRadio" label="1" border>微信支付</el-radio>
                <el-radio v-model="payRadio" label="2" border>兑换码</el-radio>
            </div>
            <el-button type="danger" @click="toPay" v-if="payRadio == '1'"
                >立即支付</el-button>
            <el-button type="danger" @click="toRedeem" v-else>立即兑换</el-button>
        </el-dialog>
    </div>
</template>
<script>
import Classtableleft from '../components/Classtableleft'
let _this
export default {
    data(){
        return{
            activeName: 'first',
            dialogVisible: false,
            studyed: false,
            loading: false,
            payRadio: '1',
            form: {},
            obj: {},
            redeemData: '',
            oftop:'',
            top:'',
            token:''
        }
    },
    components: { Classtableleft },
    created(){
        _this = this
        _this.dialogVisible=this.$route.params.signStore
        if(_this.$route.params.isfirst=='first'){
           _this.dialogVisible = true
        }
        _this.obj = this.$store.state.wps_user
    },
    mounted(){
        this.oftop = document.getElementsByClassName('el-tabs__header is-left')[0]
        window.addEventListener('scroll',this.handleScrollx,true)
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScrollx, true);
    },
    methods:{
        handleScrollx() {
            //获取滑动条位置
            this.top= 0
            if (document.documentElement && document.documentElement.scrollTop) {
                 this.top = document.documentElement.scrollTop;
            } else if (document.body) {
                 this.top = document.body.scrollTop;
            }
            if( this.top>=425){
                this.oftop.setAttribute('style', 'position: fixed;top:150px;');
                document.getElementsByClassName('el-tabs__content')[1].setAttribute('style','margin-left:190px')
            }else if(this.top<=425){
                this.oftop.setAttribute('style', 'position:relative;');
            }
        },
        study() {
            _this.studyed = true
            if (this.$store.state.wps_user.mobile) {
                _this.$http.post(process.env.VUE_APP_URL + 'isPay', { good_id: 1 })
                .then(data => {
                    this.token=data.data.token
                    if (data.data.code == 403) {
                        _this.$message.error(data.data.msg)
                        return
                    }
                    if (data.data.code == 200) {
                        _this.dialogVisible = true
                    }else {
                        _this.setNetbanCookie()
                    }
                })
                .catch(err => {
                    _this.$message.error('网络错误，请重试')
                    console.log(err)
                })
            } else {
                this.$message.warning('您还未登录，请先登录！')
            }
        },
        setNetbanCookie() {
            _this.$http.post(process.env.VUE_APP_URL + 'isPay', { good_id: 1 })
            .then(data => {
                _this.token=data.data.token
                 window.location.href='http://netban.cn/api/user/synwp/' +
                _this.token +
                '?url=http://custom.netban.cn/app/pc/?id=T20326'
            })
        },
        toRedeem() {
        //兑换码
        _this.loading = true
        let subsystem_url = window.location.href
        _this.$http
            .post(process.env.VUE_APP_URL + 'exchange', {
            good_id: 1,
            numbers: this.redeemData,
            subsystem_url: subsystem_url,
            })
            .then(data => {
            _this.loading = false
            if (data.data.code == 403 || data.data.code == 500) {
                _this.$message.error(data.data.msg)
                return
            }

            _this.redeem_number = data.data.data
            if (_this.redeem_number) {
                window.location.href =
                'http://pay.netban.cn/pay/' + this.redeem_number //跳转至支付页面
            }
            if (!_this.obj.is_verfy) {
                _this.warningDialog = true
            } else {
                if (_this.toForm.istrue) {
                _this.papers(_this.toForm.istrue)
                } else {
                _this.setNetbanCookie()
                }
            }
            _this.dialogVisible = false
            })
            .catch(err => {
            _this.$message.error('网络错误，请重试')
            _this.loading = false
            console.log(err)
            })
        },
        toPay() {
            //去支付
            this.toExam()
        },
        toExam() {
            let subsystem_url = window.location.href
            _this.$http.post(process.env.VUE_APP_URL + 'order', {good_id: 1,subsystem_url: subsystem_url,})
            .then(data => {
                if (data.data.code == 200) {
                    //未付款
                    _this.order_number = data.data.data
                    window.location.href =
                    'http://pay.netban.cn/pay/' + this.order_number //跳转至支付页面
                    // _this.dialogVisible = true;
                } else if (data.data.code == 201&&data.data.token!='') {
                //    _this.$message.error(data.data.msg)
                    _this.setNetbanCookie()
                } else {
                    _this.$message.error(data.data.msg)
                }
            }).catch(err => {
                _this.$message.error('网络错误，请重试')
                console.log(err)
            })
        },
    },
}
</script>
<style lang="less" scoped>
    .Traincourse{
        width: 100%;
        .introduce{
            max-width: 1200px;
            margin: 0px auto;
            padding: 50px 0px;
            h1{
                font-size: 28px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #000005;
            }
            p{
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #7C8087;
                line-height: 34px;
                margin: 10px 0px 20px;
            }
            img{
                width: 100%;;
            }
            .el-button{
                width: 222px;
                height: 40px;
                background: #960F23;
                border-radius: 4px;
                color:#FFFFFF;
                font-size: 16px;
            }
        }
        .tabs{
            /deep/.el-tabs__nav-scroll {
                max-width: 800px;
                margin: 0px auto;
                .el-tabs__item{
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: 500;
                    color: #000005;
                    line-height: 20px;
                }
            }
            /deep/.el-tabs__nav-wrap::after{
                height: 0px!important;
            }
            /deep/.el-tabs__active-bar{
                height: 3px;
                background: #960F23;
            }
            /deep/.el-tabs__content{
                background-color: #FCF8F5;
                .info{
                    // 介绍
                    max-width: 1200px;
                    padding:20px 0px 50px;
                    margin: 0px auto;
                    .info_txt{
                        padding:20px;
                        border:1px dashed #C8A35E;
                        .xuhao{
                            width: 40px;
                            height: 28px;
                        }
                    }
                    // 目标
                    .target_item{
                        background-color: #FFFFFF;
                        padding: 10px auto 20px;
                        .tag{
                            width:95%;
                            position: relative;
                            right:5%;
                            top: 20px;
                        }
                        .cont{
                            width: 65%;
                            margin: 10px auto 20px;
                            .cover{
                                width: 60%;
                                margin: 20% 0px 15px 20%;
                            }
                            p{
                                text-align: center;
                                font-size: 16px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #2B2B2B;
                                line-height: 20px;
                            }
                        }
                    }
                }
                .ability{
                    padding:20px 0px 100px;
                    background-color: #FFFFFF;
                    .imgs_item{
                        max-width: 1200px;
                        margin: 0px auto;
                        h2{
                            font-size: 20px;
                            font-family: Alibaba PuHuiTi;
                            font-weight: bold;
                            color: #333333;
                            margin: 30px auto 15px;
                            text-align: center;
                        }
                        img{
                            width: 100%;
                        }
                    }
                }
                .why{
                    padding:25px 0px 0px;
                    background-color: #FFFFFF;
                    .card{
                        max-width: 900px;
                        padding: 50px 140px;
                        background: #FFFFFF;
                        box-shadow: 0px 2px 21px 0px rgba(134, 99, 69, 0.14);
                        position: relative;
                        z-index: 1;
                        p{
                            font-size: 18px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            line-height: 34px;
                            position: relative;
                            top: -4px;
                        }
                        span{
                            color: #B72F24;
                        }
                        ul{
                            list-style-type: disc;
                            ::marker{
                                font-size: 30px;
                            }
                        }
                    }
                    .ban{
                        width: 100%;
                        height: 153px;
                        background: #960F23;
                        text-align: center;
                        position: relative;
                        bottom: 76px;
                        .el-button{
                            width: 222px;
                            height: 40px;
                            margin-top: 54.5px;
                            background: #CE9A6B;
                            border-radius: 4px;
                            color:#FFFFFF;
                            font-size: 16px;
                            border: none;
                            position: relative;
                            z-index: 99;
                        }
                    }
                }
                .what{
                    max-width: 1200px;
                    margin: 0px auto;
                    .item{
                        margin-left: 2%;
                        width: 96%;
                        height: 142px;
                        background-color: #FFFFFF;
                        margin-top: 20px;
                        img{
                            width: 12%;
                            margin: calc(71px - 5%);
                        }
                        span{
                            font-size: 26px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            line-height:142px;
                            position: relative;
                            top:-58px
                        }
                    }
                }
                .Mask{
                    background-color: #FCF8F5;
                    position: relative;
                    top: -76px;
                    padding-top: 30px;
                }
            }
        }
        .img_title{
            width: 40%;
            margin: 0px 0px 20px 30%;
        }
        .lang{
            width: 44%;
            margin: 0px 0px 20px 28%;
        }
        /deep/.el-tabs__active-bar.is-left {
            height: 35px!important;
        }
        .mx1200{
            max-width: 1200px;
            margin: 0px auto;
        }
        .table{
            padding: 40px 0px;
            margin: 0px auto;
            background-color: #FFFFFF;
            /deep/.el-tabs__content{
                background-color: #FFFFFF!important;
            }
            /deep/.el-tabs--left{
                max-width: 1200px;
                margin: 0px auto;
            }
            /deep/.el-tabs__item{
                font-size:14px!important;
                line-height: 2.5!important;
            }
            /deep/.el-tabs__item.is-active{
                color: #960F23;
            }
        }
    }
.el-dialog {
    .el-input {
      width: 270px;
      margin: 10px 0;
    }
    .el-button--danger {
      background-color: #960f23;
      width: 100px;
      margin-top: 50px;
    }
  }
  .uploading {
    margin-bottom: 100px;
  }
  .redeem {
    padding: 10px 10px 0 0;
  }
  .toRedeem {
    width: 120px !important;
    margin-left: 14px;
  }
  .cancel {
    margin-right: 225px;
  }
  .examselect {
    p {
      margin: 12px;
      font-size: 15px;
      span {
        color: red;
      }
    }
    div {
      padding-top: 20px;
      .el-button {
        width: 110px;
      }
    }
  }
  .el-radio {
    margin: 6px;
  }
  .download {
    padding-top: 10px;
    font-size: 15px;
    .el-link {
      color: red;
      font-size: 16px;
    }
  }
  .toPdf {
    h2 {
      margin-bottom: 25px;
      font-size: 20px;
      color: #960f23;
    }
    div {
      margin: 5px;
      font-size: 16px;
    }
  }
  .dialog_table {
    .container {
      height: 70vh;
    }
    /deep/.el-dialog__header {
      text-align: center;
      font-weight: bold;
      color: #000000;
    }
    /deep/.el-dialog__body {
      padding: 10px 100px 20px;
    }
  }
  /deep/.el-form-item__label {
    line-height: 60px;
  }
  .warnRed {
    color: red;
    padding-left: 100px;
    font-size: 13px;
  }
  .payStyle {
    div {
      font-size: 15px;
      margin: 10px;
    }
    span {
      font-size: 16px;
      color: #960f23;
      padding-right: 10px;
    }
    .el-button {
      margin-left: 205px;
    }
}
</style>